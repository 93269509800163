// replaceTemplate.js
'use strict';

/**
 * Replace template
 * @param {string} string - template string.
 * @param {object} data - data object.
 * @return {string} return replaced string.
 */
const replaceTemplate = ({ string, data } = {}) => {
  if (!string || !data) {
    return string;
  }
  // %7B is left curly bracket
  // %7D is right curly bracket
  return string.replace(/({([a-z_]+)}|%7B([a-z_]+)%7D)/gi, substring => {
    const key = substring.replace(/({|}|%7B|%7D)/gi, '');
    return data[key] || '';
  });
};

export default replaceTemplate;
