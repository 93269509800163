// shopConstants.js
'use strict';

export const SHOP_ID = {
  addvalue: 'addvalue',
  topup: 'top-up',
  messagePack: 'messagePack',
  subscription: 'subscription',
};

export const orderStatusConstant = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
};

export const orderStatusWhiteList = [
  orderStatusConstant.PENDING,
  orderStatusConstant.SUCCEEDED,
  orderStatusConstant.CANCELLED,
  orderStatusConstant.FAILED,
];

export const PaymentMethodUsage = {
  SUBSCRIPTION: 'subscription',
  TOP_UP: 'top-up',
  ALL: 'all',
};

export const PaymentGateway = {
  SECURIONPAY: 'securionpay',
  TAPPAY: 'tappay',
};

export const ProductType = {
  DIAMONDS: 'diamonds',
  SUBSCRIPTIONS: 'subscriptions',
  MESSAGEPACKS: 'messagepacks',
};

export const SHOP_ROOT = 'shop-root';

export const InternalCurrency = {
  DIAMONDS: 'DIAMONDS',
};

export const OrderStatus = {
  BOUGHT: 'bought',
};

export const ServerOrderStatus = {
  PAID: 'paid',
  FULFILLED: 'fulfilled',
  REFUNDED: 'refunded',
};
