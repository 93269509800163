// getOperationData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import getRemoteConfigData from './getRemoteConfigData.js';
import { PUBLIC_APP_URL_PATH } from '../RemoteConfigKeys.js';

const defaultObject = {};

/**
 * Select operation data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - data key.
 * @return {any} The selected operation data.
 */
const getOperationData = createCachedSelector(
  (state, selectPath) => state.getIn(['operations', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  state => getRemoteConfigData(state, PUBLIC_APP_URL_PATH),
  (operation, dataKey, publicAppUrlPath) => {
    let result = undefined;
    if (operation) {
      result = !dataKey ? operation : operation.get?.(dataKey);

      if (dataKey === 'streamTokenNbf') {
        const currentChargeRange = operation.get('currentChargeRange');
        result = currentChargeRange
          ? currentChargeRange?.split?.('~')[0]
          : null;
      } else if (dataKey === 'streamTokenExp') {
        const currentChargeRange = operation.get('currentChargeRange');
        result = currentChargeRange
          ? currentChargeRange?.split?.('~')[1]
          : null;
      } else if ('resumableMessageItemIds' === dataKey) {
        const resumableMessageItemIds = operation.reduce((acc, value, key) => {
          const progress = value.get('progress');
          if (progress && progress < 100 && progress >= -1) {
            acc.push(key);
          }
          return acc;
        }, []);
        result = resumableMessageItemIds;
      } else if ('appData' === dataKey) {
        const downloadString = operation || '';
        if (!downloadString) {
          return defaultObject;
        }
        const [packageName, appUrl] = downloadString.split(' ');
        const logoUrl = getResourceUrl({
          path: publicAppUrlPath,
          endpoint: `/${packageName}.svg`,
          resourceType: PUBLIC,
        }).href;
        return { packageName, appUrl, logoUrl };
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, selectPath, dataKey) => `${selectPath.join(':')}:${dataKey}`);

export default getOperationData;
