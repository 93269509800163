// getHashColor.js

export const CHATROOM_AVATAR_COLORS = [
  '#c83539',
  '#861e1e',
  '#ff885c',
  '#e85435',
  '#f6a12b',
  '#ddbc45',
  '#8fc231',
  '#398431',
  '#67b78b',
  '#009584',
  '#73b4da',
  '#2c79b5',
  '#3938c9',
  '#846ee1',
  '#9340d7',
  '#c484e2',
  '#9d1ba6',
  '#a01864',
  '#a6567c',
  '#bc8181',
  '#a09175',
  '#937928',
  '#648153',
  '#51758c',
  '#7a518d',
  '#3c593c',
  '#6d3554',
];

// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
export function hashCode({ str }) {
  let hash;
  if (!str) return 0;
  for (let i = 0; i < str.length; i++)
    hash = (Math.imul(31, hash) + str.charCodeAt(i)) | 0;

  return hash;
}

export function getHashColor({ hashStr }) {
  const index =
    Math.abs(hashCode({ str: hashStr })) % CHATROOM_AVATAR_COLORS.length;
  return CHATROOM_AVATAR_COLORS[index];
}
