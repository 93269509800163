// getMaskedUsername.js

export function getMaskedUsername({ username }) {
  if (!username) return '';
  switch (username.length) {
    case 1:
    case 2:
      return `${username}`;
    case 3:
    case 4:
    case 5:
      return `${username.slice(0, 2)}${'***'.slice(5 - username.length)}`;
    case 6:
      return `${username.slice(0, 2)}***${username[username.length - 1]}`;
    default:
      return `${username.slice(0, 2)}***${username[username.length - 2]}${
        username[username.length - 1]
      }`;
  }
}
