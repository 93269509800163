// createCachedSelector.js
'use strict';
import originalCreateCachedSelector, { LruMapCache } from 're-reselect';
import { createSelector as originalCreateSelector } from 'reselect';

const MAX_CACHED_SELECTORS_SIZE = +process.env.MAX_CACHED_SELECTORS_SIZE || 32;
const MAX_SELECTOR_LRU_SIZE = +process.env.MAX_SELECTOR_LRU_SIZE || 32;

const isServer = typeof window === 'undefined';

if (isServer && !globalThis.__CACHED_SELECTORS__)
  globalThis.__CACHED_SELECTORS__ = [];

// FIFO
const addToCachedSelectors = selector => {
  if (globalThis.__CACHED_SELECTORS__.length >= MAX_CACHED_SELECTORS_SIZE) {
    const removedSelectors = globalThis.__CACHED_SELECTORS__.splice(
      0,
      globalThis.__CACHED_SELECTORS__.length - MAX_CACHED_SELECTORS_SIZE + 1
    );

    removedSelectors.forEach(removedSelector =>
      removedSelector?.clearCache?.()
    );
  }

  globalThis.__CACHED_SELECTORS__.push(selector);
};

const createCachedSelector =
  (...funcs) =>
  (polymorphicOptions, legacyOptions) => {
    let options = polymorphicOptions;

    if (isServer) {
      options =
        typeof polymorphicOptions === 'function'
          ? {
              keySelector: polymorphicOptions,
            }
          : Object.assign({}, polymorphicOptions);

      // LRU for each selector
      options.cacheObject = new LruMapCache({
        cacheSize: MAX_SELECTOR_LRU_SIZE,
      });
    }

    const selector = originalCreateCachedSelector(...funcs)(
      options,
      legacyOptions
    );

    if (isServer) addToCachedSelectors(selector);

    return selector;
  };

export const createSelector = (...funcs) => {
  const selector = originalCreateSelector(...funcs);

  if (isServer) addToCachedSelectors(selector);

  return selector;
};

export default createCachedSelector;
